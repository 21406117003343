import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import Image from "../ui/Image";
import { get_base_uri } from "../../config";

const Variable = ({
  unique_id,
  remove,
  variable,
  activeMode,
  selectedWord,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <span
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => remove(unique_id)}
      key={unique_id}
      className="px-1 cursor-pointer rounded bg-lpurple relative text-white flex items-center justify-center"
      style={{ position: "relative", display: "inline-block" }} // Add this style
    >
      {hover && (
        <Image
          className="absolute top-0 right-5 transform translate-x-1/2 -translate-y-1/2" // Adjust positioning
          src="/cross.png"
          alt="cross"
          width={8}
          height={8}
          variable={variable}
          style={{ backgroundColor: "transparent", color: "lpurple" }} // Set background to transparent and color to lpurple
        />
      )}
      <span>{selectedWord}</span>
    </span>
  );
};

export default function UploadVariables({ setProjectStatus, projectStatus, projectId, setdownloadVideoURl }) {
    const navigate = useNavigate();
    const [projectName, setProjectName] = useState("");
    const [project, setProject] = useState({});
    const [downloadVideurl, setDownloadVideUrl] = useState("");
    const [userId, setUserId] = useState("");
    const [currentSelection, setCurrentSelection] = useState(null);
    const [selections, setSelections] = useState([]);
    const [transcription, setTranscription] = useState([]);
    const [variables, setVariables] = useState(0);
    const [sampleRow, setSampleRow] = useState([]);
    const [status, setStatus] = useState("");
    const [downloadBtnText, setDownloadBtnText] = useState("Download Videos");
    const [activeMode, setActiveMode] = useState(false);
    const [mouseDown, setMouseDown] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    var renderedVariables = false;   

  useEffect(() => {
    setdownloadVideoURl(downloadVideurl);
  }, [downloadVideurl]);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const response = await axios.get(
          `${get_base_uri()}/api/v1/projects/${projectId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          }
        );
        const project = response.data;
        setProject(project);
        setDownloadVideUrl(project.output);
        setProjectName(project.project_name);
        setUserId(project.user_id);
        setStatus(project.status);
        if(project.n_recordings_completed > 0 && project.status === "partial_generation_done"){
          setDownloadBtnText(`Download ${project.n_recordings_completed} Videos`);
        }
        else{
          setDownloadBtnText(`Download Videos`);
        }
        if (project.status === "transcription_done") {
          setActiveMode(true);
        } else if (
          project.status === "starting_generation" ||
          project.status === "partial_generation_done" ||
          project.status === "video_generation_in_progress"
        ) {
          setIsGenerating(true);
          setShowLoader(true);
        }
        setTranscription(project.transcription.split(" "));
        setVariables(project.n_variables);
        setSampleRow(project.sample_row);
        setSelections(
          project.selected_variables && project.selected_variables.length
            ? project.selected_variables
            : new Array(project.n_variables).fill(null)
        );
      } catch (error) {}
    };
    fetchProject();
  }, [projectId]);

    useEffect(() => {
        if (status === 'generation_done' && downloadVideurl !== "" && downloadVideurl !== null) {
            setIsGenerating(false);
            setShowLoader(false);
        }
    }, [status]);

  const isSelected = (num) => {
    const { start, end } = currentSelection || { start: null, end: null };
    if (start == null && end == null) {
      return false;
    }
    return num >= start && num <= end;
  };

  const insert = (num) => {
    if (currentSelection) {
      const { start, end, isAnchorStart } = currentSelection;
      if (num === start || num === end) {
        // console.log(currentSelection, num)
        setCurrentSelection(null);
        return;
      }
    }
    let newSelection = {
      ...(currentSelection || {
        start: num,
        end: num,
        isAnchorStart: true,
      }),
    };
    const { start, end, isAnchorStart } = newSelection;

    if (num < start) {
      if (!isAnchorStart) {
        newSelection = {
          start: num,
          end,
          isAnchorStart: false,
        };
      } else {
        newSelection = {
          start: num,
          end: start,
          isAnchorStart: false,
        };
      }
    } else if (num > end) {
      if (isAnchorStart) {
        newSelection = {
          start,
          end: num,
          isAnchorStart: true,
        };
      } else {
        newSelection = {
          start: end,
          end: num,
          isAnchorStart: true,
        };
      }
    } else if (isAnchorStart) {
      newSelection = {
        start,
        end: num,
        isAnchorStart: true,
      };
    } else {
      newSelection = {
        start: num,
        end,
        isAnchorStart: false,
      };
    }

    // Check if the new selection overlaps with any existing selections
    if (
      selections.some((selection) => {
        if (!selection) return false;
        const { start: sStart, end: sEnd } = selection;
        return (
          (newSelection.start <= sStart && newSelection.end >= sStart) ||
          (newSelection.start <= sEnd && newSelection.end >= sEnd)
        );
      })
    )
      return;

    setCurrentSelection(newSelection);
  };

  const removeVariable = (i) => {
    setSelections((prev) => {
      const newSelections = [...prev];
      newSelections[i] = null;
      return newSelections;
    });
    setTranscription((prev) => {
      const newTranscription = [...prev];

      if (!selections || !selections[i]) return newTranscription; // Check if selections or selections[i] is undefined
      const { start, end } = selections[i];
      for (let j = start; j <= end; j++) {
        newTranscription[j] = project.transcription.split(" ")[j];
      }
      return newTranscription;
    });
  };

  const handleSubmit = () => {
    setIsGenerating(true);
    setShowLoader(true);
    const sorted_selection = [...selections].sort((a, b) => a.start - b.start);

        let data = {
            status: 'starting_generation',
            selected_variables: sorted_selection,
        };
        const token = localStorage.getItem("accessToken");
        axios
            .put(
                `${get_base_uri()}/api/v1/projects/${projectId}/update/status/`,
                data,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(() => {
                const interval = setInterval(async () => {
                    try {
                        const response = await axios.get(
                            `${get_base_uri()}/api/v1/projects/${projectId}`,
                            {
                                headers: { Authorization: `Bearer ${token}` },
                                withCredentials: true,
                            }
                        );
                        const updatedProject = response.data;
                        setStatus(updatedProject.status);
                        if(project.n_recordings_completed > 0 && updatedProject.status === "partial_generation_done"){
                            setDownloadBtnText(`Download ${updatedProject.n_recordings_completed} Videos`);
                        }else{
                            setDownloadBtnText(`Download Videos`);
                        }
                        setDownloadVideUrl(updatedProject.output);
                        if (updatedProject.status === 'generation_done' && downloadVideurl !== "" && downloadVideurl !== null)  {
                            clearInterval(interval);
                            setIsGenerating(false);
                            setShowLoader(false);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }, 5000);
            })
            .catch((error) => {
                setIsGenerating(false);
                setShowLoader(false);
            });
    };

  const isSelectionStarted = (n) => {
    for (let i = 0; i < selections.length; i++) {
      const selection = selections[i];

      if (!selection) continue;
      const { start, end } = selection;
      if (n === start) {
        return true;
      } else if (n > start && n <= end) return true;
    }
    return false;
  };

  const getWordSelection = (n) => {
    for (let i = 0; i < selections.length; i++) {
      const selection = selections[i];

      if (!selection) continue;
      const { start, end } = selection;
      if (n === start) {
        return i;
      } else if (n > start && n <= end) return i;
    }
    return null;
  };

  const isSelctionEnd = (n) => {
    for (let i = 0; i < selections.length; i++) {
      const selection = selections[i];

      if (!selection) continue;
      const { start, end } = selection;
      if (n === end) return true;
    }
    return false;
  };
  const addSelection = (i) => {
    setSelections((prev) => {
      const newSelections = [...prev];
      if (currentSelection) {
        newSelections[i] = currentSelection;
        const { start, end } = currentSelection;
        const newTranscription = [...transcription];
        for (let j = start; j <= end; j++) {
          newTranscription[j] = transcription[j]; // Set the selected word(s) in the transcription state
        }
        setTranscription(newTranscription);
      }
      return newSelections;
    });
    setCurrentSelection(null);
  };
  const remove = () => {
    return;
  };

  const getTranscriptionSlice = (selection) => {
    if (!selection) return "";
    const { start, end } = selection;
    const a = [...transcription];
    const startIndex = start !== 0 ? start : 0;
    return a.slice(startIndex, end + 1).join(" ");
  };

    return (
        <div className="relative">
            <h3 className="font-lato font-bold text-2xl text-black mb-5">{status === 'generation_done' && downloadVideurl !== "" && downloadVideurl !== null ? "Download Videos" : "Select Variables"}</h3>
            <div className="flex gap-11 max-xl:flex-col max-xl:items-center">
                <div className="max-w-[1200px] flex gap-3">
                    <div className="flex w-1/3 flex-col items-center gap-3">
                        <div className="flex flex-col w-full items-center">
                            {sampleRow.map((variable, i) => {
                                const selection = selections[i];
                                return (
                                    <div className="flex mt-2 w-full items-center bg-white border-slate-500 p-2 gap-2 justify-start border-1" key={i}>
                                        <span className="p-1 bg-gray-300 text-bold text-lg">{variable}</span>
                                        {selection === null ? (
                                            <Button
                                                className="ml-auto bg-lpurple text-white hover:bg-light-purple flex items-center gap-1.5"
                                                onClick={isGenerating ? undefined : () => addSelection(i)}
                                            >
                                                Add V{i + 1} <span className="pl-1">{`<-`}</span>
                                            </Button>
                                        ) : (
                                            <Button
                                                className="ml-auto bg-lpurple w-32 text-white hover:bg-customGray flex items-center gap-1.5 overflow-hidden whitespace-nowrap"
                                                onClick={isGenerating ? undefined : () => removeVariable(i)}
                                            >
                                                <span className="truncate">
                                                    {getTranscriptionSlice(selection)}
                                                </span>

                        <Image
                          className="pl-1"
                          src="/cross.png"
                          alt="cross"
                          width={8}
                          height={8}
                        />
                      </Button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-1 border-r border-gray-300"></div>
          <div className="w-4/4 flex-grow flex-col items-center">
            <div className="flex justify-center relative">
              {showLoader && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                  <div className="flex flex-col items-center">
                    {" "}
                    {/* Added flex flex-col here */}
                    <svg
                      className="animate-spin h-12 w-12 text-white mr-3"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>

                      <path
                        className="opacity-75"
                        fill="purple"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                      ></path>
                    </svg>
                    <span className="text-white block">
                      Generating the Video ...
                    </span>
                  </div>
                </div>
              )}
              <img
                className="max-w-[377px] object-center rounded-[20px] shadow-[0_0px_7.5px_5px_rgba(0,0,0,0.25)]"
                src={`${project.thumbnail}`}
                alt="project thumbnail"
              />
            </div>

                        <p className="font-lato font-normal italic text-black text-base text-center leading-none max-w-[746px] mt-4">
                            Please select the word or group of words inside the text to replace with the values in your spreadsheet
                        </p>
                        <div className="relative w-full max-w-[946px] min-h-[150px] flex-grow rounded flex-wrap flex content-start p-2 bg-white mt-4" style={{ fontSize: '1rem', lineHeight: "1.4rem" }}
    onMouseUp={() => {
        setMouseDown(true);
    }}
    onMouseDown={(e) => {
        e.preventDefault();
    }}
>
    {transcription.map((word, i) => {
        let isSelctionWords = isSelectionStarted(i);
        let isEnd = isSelctionEnd(i);
        let actual_word = word;
        if (isSelctionWords || isEnd) {
            if (!isEnd) {
                actual_word = "";
            } else {
                actual_word = "V" + (getWordSelection(i) + 1);
            }
        }
        return (
            <span onClick={status === 'generation_done' ? undefined : () => { insert(i) }}
                className={`${actual_word !== "" ? "p-1 m-[0.041rem]" : ""} hover:bg-[#C1B5FF] cursor-pointer rounded ${isSelected(i) || isSelctionWords ? "bg-[#C1B5FF]" : ""}`}
                key={i} onChange={(e) => {
                    e.preventDefault();

                    if (currentSelection && isSelected(i)) {
                        setMouseDown(false);
                        setCurrentSelection(null);
                    } else if (selections.some((selection) => !selection)) {
                        setCurrentSelection({
                            start: i,
                            end: i,
                            isAnchorStart: true,
                        });

                        return;
                    }
                }}
            >
                {actual_word}
            </span>
        );
    })}
</div>

<div className="relative flex items-center justify-center mt-8">
    {(status === 'generation_done' || status === 'partial_generation_done') && downloadVideurl !== "" && downloadVideurl !== null ? (
        <a
            href={downloadVideurl}
            className="mb-20 bg-lpurple hover:bg-lpurple-700 disabled:bg-[#B1B1B1] flex items-center gap-6 py-3.5 px-[54px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] rounded-[11px] hover:bg-purple-800 w-80"
        >
            <img
                src="/downloads.svg" // Replace with the path to your icon image
                alt="Download Icon"
                className="h-6 w-6 text-white" // Adjust the size and color as needed
            />
            <span className="font-lato font-medium text-white md:text-base lg:text-lg">
                {downloadBtnText}
            </span>
        </a>
    ) : (
        <Button className={`${isGenerating ? 'bg-gray-400' : 'bg-lpurple hover:bg-lpurple/80'} disabled:bg-[#B1B1B1] flex items-center gap-8 px-[54px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] rounded-[11px]`}
            onClick={handleSubmit}
            disabled={isGenerating}
        >
            <Image
                className="max-lg:w-[40px] h-auto"
                src={isGenerating ? 'downloads.svg' : 'video.svg'}
                width={27}
                height={19}
                alt="video"
                remove={remove}
            />
            <span className="font-lato font-medium text-white md:text-base">
                {isGenerating ? downloadBtnText : 'Generate Video'}
            </span>
        </Button>
    )}
</div>

                    </div>
                </div>
            </div>
        </div>
    );
}
